import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';

import * as ScenarioActions from 'app/actions/scenarios/scenarioAction.js';
import {
  CloudQueue,
  DeleteOutline,
  EditAttributes,
  InfoOutlined,
  SaveOutlined,
  Settings,
  PlaylistAddCheck,
  FileCopyOutlined
} from '@material-ui/icons';
import { RecognitionSelector, ScenarioChartSelectors, ScenarioSelectors } from 'app/selectors/index.js';
import useStyles from './styles.js';
import { chartNodes } from 'app/selectors/scenarioChartSelectors.js';
import { CustomNodeTypeEnum } from 'app/models/intents/customNode.js';
import medicalIndexSlice from 'app/store/MedicalIndex/medicalIndexSlice.js';
import useScenarioMutations from 'app/mutations/scenarioMutations.js';
import { isUserAnyAdminSelector, isUserSuperAdminSelector, isUserOwnerSelector } from 'app/selectors/userSelectors.js';
import SharedScenarioSettingsDialog from '../SharedScenarioSettingsDialog/index.js';
import LoadingButton from 'app/components/LoadingButton.js';
import { LoadingStatus } from 'app/store/types.js';
import AddVariableDialog from '../Variables/AddVariableDialog.js';
import { getVariablesQuery } from 'app/queries/variableQueries.js';
import { useQuery } from '@tanstack/react-query';
import findUnsavedVariables from 'app/utils/variableUtils.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

const { actions: MedicalIndexActions } = medicalIndexSlice;

const ScenarioActionsSection = (props: {
  onToggleDialogShow: () => void;
  openCopy: () => void;
  openSettings: () => void;
  closeSettings: () => void;
  setFormScenarioId: (scenarioId: number | undefined) => void;
  showSummary: () => void;
  showChangelog: () => void;
  showVariables: () => void;
  isSharedScenario: boolean;
}) => {
  const { isSharedScenario } = props;
  const isSuperAdmin = useSelector(isUserSuperAdminSelector);
  const isUserOwner = useSelector(isUserOwnerSelector);
  const isAnyAdmin = useSelector(isUserAnyAdminSelector);
  const currentScenario = useSelector(ScenarioSelectors.currentScenario);

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const { convertScenarioToShared } = useScenarioMutations();

  const [convertSharedOpen, setConvertSharedOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const { id: scenarioId } = useParams<{ id: string }>();
  //TODO need to exclude menu and their functions to separate component
  const linkedScenarios = useSelector(ScenarioSelectors.linkedScenarios);
  const isDialogActive = useSelector(RecognitionSelector.isDialogActive);
  const dispatch = useDispatch();

  const openEditing = () => {
    props.setFormScenarioId(Number.parseInt(scenarioId, 10));
    props.openSettings();
  };
  const closeForm = () => {
    props.setFormScenarioId(undefined);
    props.closeSettings();
  };

  useEffect(() => {
    dispatch(MedicalIndexActions.load());
  }, [dispatch]);

  // const links = useSelector(chartLinks);
  const nodes = useSelector(chartNodes);

  const isAllNodesConnected = (() => {
    // const outgoingLinks = Object.values(links).map((l) => l.to.nodeId);
    const blocks = Object.values(nodes);
    return (
      (blocks.some((x) => x.type === CustomNodeTypeEnum.Start) &&
        blocks.some((x) => x.type === CustomNodeTypeEnum.End)) ||
      linkedScenarios.length > 1
    );
    // && !blocks.filter((b) => b.type === CustomNodeTypeEnum.End).some((b) => !outgoingLinks.includes(b.id))
  })();

  const classes = useStyles();

  const variablesQuery = React.useMemo(() => getVariablesQuery(Number(scenarioId)), [scenarioId]);
  const queryData = useQuery(variablesQuery);
  const variables = queryData.data ?? [];

  const [openAddVariables, setOpenAddVariables] = useState(false);

  const currentChartScenario = useSelector(ScenarioSelectors.currentChartScenario);
  const currentChart = useSelector(ScenarioChartSelectors.scenarioChart);
  const [unsavedVariables, setUnsavedVariables] = useState<string[]>([]);

  const onSaveScenario = () => {
    // Search through scenario to find unsaved variables
    const foundUnsaved = findUnsavedVariables(currentChartScenario, Object.values(currentChart.nodes), variables);
    if (foundUnsaved.length > 0) {
      setUnsavedVariables(foundUnsaved);

      setOpenAddVariables(true);
    } else {
      dispatch(ScenarioActions.saveDetailedScenario());
    }
  };

  const onSaveScenarioAfterSavedVariables = () => {
    setOpenAddVariables(false);
    setUnsavedVariables([]);
    dispatch(ScenarioActions.saveDetailedScenario());
  };

  return (
    <>
      {(isSharedScenario && isSuperAdmin) || (isSharedScenario && isUserOwner) || (!isSharedScenario && isAnyAdmin) ? (
        <>
          <Button
            data-cy="saveScenarioButton"
            startIcon={<SaveOutlined className={classes.actionDefault} />}
            disabled={isDialogActive || !isAllNodesConnected}
            onClick={onSaveScenario}
          >
            <Typography className={classes.transformNone}>
              {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioActionsSection.save))}{' '}
            </Typography>
          </Button>
          <Button
            disabled={isDialogActive}
            startIcon={<FileCopyOutlined className={classes.actionDefault} />}
            onClick={props.openCopy}
          >
            <Typography className={classes.transformNone}>
              {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioActionsSection.copy))}
            </Typography>
          </Button>

          <Button
            data-cy="deleteScenarioButton"
            onClick={props.onToggleDialogShow}
            startIcon={<DeleteOutline className={classes.actionDefault} />}
          >
            <Typography className={classes.transformNone}>
              {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioActionsSection.delete))}
            </Typography>
          </Button>

          <Button startIcon={<Settings className={classes.actionDefault} />} onClick={openEditing}>
            <Typography className={classes.transformNone}>
              {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioActionsSection.settings))}
            </Typography>
          </Button>

          <Button startIcon={<InfoOutlined className={classes.actionDefault} />} onClick={props.showSummary}>
            <Typography className={classes.transformNone}>
              {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioActionsSection.summary))}
            </Typography>
          </Button>

          <Button startIcon={<PlaylistAddCheck className={classes.actionDefault} />} onClick={props.showChangelog}>
            <Typography className={classes.transformNone}>
              {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioActionsSection.changelog))}
            </Typography>
          </Button>
        </>
      ) : null}

      {isAnyAdmin ? (
        <Button startIcon={<EditAttributes className={classes.actionDefault} />} onClick={props.showVariables}>
          <Typography className={classes.transformNone}>
            {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioActionsSection.variables))}
          </Typography>
        </Button>
      ) : null}

      {isSuperAdmin && currentScenario ? (
        <>
          {currentScenario.companyId ? (
            <Button startIcon={<CloudQueue className={classes.actionDefault} />} onClick={() => setConfirmOpen(true)}>
              <Typography className={classes.transformNone}>
                {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioActionsSection.convertToShared))}
              </Typography>
            </Button>
          ) : (
            <>
              <Button
                startIcon={<CloudQueue className={classes.actionDefault} />}
                onClick={() => setConvertSharedOpen(true)}
              >
                <Typography className={classes.transformNone}>
                  {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioActionsSection.sharedSettings))}
                </Typography>
              </Button>
              <SharedScenarioSettingsDialog
                open={convertSharedOpen}
                onClose={() => setConvertSharedOpen(false)}
                scenarioId={Number.parseInt(scenarioId, 10)}
              />
            </>
          )}
        </>
      ) : null}
      <Dialog
        title={translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioActionsSection.confirmConvert))}
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
      >
        <DialogContent>
          <Typography color="error">
            {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioActionsSection.warningConvert))}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="default" onClick={() => setConfirmOpen(false)}>
            {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}
          </Button>
          <LoadingButton
            loadingStatus={convertScenarioToShared.isLoading ? LoadingStatus.Loading : LoadingStatus.Idle}
            variant="contained"
            color="primary"
            onClick={async () => {
              await convertScenarioToShared.mutateAsync(Number.parseInt(scenarioId, 10));
              setConfirmOpen(false);
            }}
          >
            {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioActionsSection.convert))}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <AddVariableDialog
        variables={variables}
        open={openAddVariables}
        onClose={() => setOpenAddVariables(false)}
        afterAdd={onSaveScenarioAfterSavedVariables}
        scenarioId={Number(scenarioId)}
        multiple
        controlledVariables={unsavedVariables}
      />
      {/* <DeleteConfirmationDialog
        title="Are you sure?"
        confirmationText="Convert"
        loadingStatus={LoadingStatus.Idle}
        DialogProps={{ open: confirmOpen }}
        onClose={() => setConfirmOpen(false)}
        onConfirm={}
      /> */}
    </>
  );
};

export default ScenarioActionsSection;
